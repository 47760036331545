import { default as _91_46_46_46slug_934qXwcRiiwiMeta } from "/opt/build/repo/pages/[articleType]/[...slug].vue?macro=true";
import { default as _91page_93biMiBPLlngMeta } from "/opt/build/repo/pages/[page].vue?macro=true";
import { default as articles4beGdLJ9a7Meta } from "/opt/build/repo/pages/articles.vue?macro=true";
import { default as _91slug_93BY6jyzicfgMeta } from "/opt/build/repo/pages/event-test/[slug].vue?macro=true";
import { default as indexlbO9ZEIRpNMeta } from "/opt/build/repo/pages/event-test/index.vue?macro=true";
import { default as _91slug_93XqrceGdm0QMeta } from "/opt/build/repo/pages/events/[slug].vue?macro=true";
import { default as indexHR5Bie6djpMeta } from "/opt/build/repo/pages/events/index.vue?macro=true";
import { default as _91office_93NnlbjqulkEMeta } from "/opt/build/repo/pages/get-in-touch/[office].vue?macro=true";
import { default as indexpzMRyvQTTbMeta } from "/opt/build/repo/pages/get-in-touch/index.vue?macro=true";
import { default as people_45backupN2dNckfYn6Meta } from "/opt/build/repo/pages/get-in-touch/people-backup.vue?macro=true";
import { default as people_45oldStfWHibio9Meta } from "/opt/build/repo/pages/get-in-touch/people-old.vue?macro=true";
import { default as peopleXyWuPsSDI9Meta } from "/opt/build/repo/pages/get-in-touch/people.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slugs_93MJlZCJGoKbMeta } from "/opt/build/repo/pages/job/[...slugs].vue?macro=true";
import { default as index95wy4H89d6Meta } from "/opt/build/repo/pages/join-us/index.vue?macro=true";
import { default as _91slug_93o07mNpgqhYMeta } from "/opt/build/repo/pages/join-us/jobs/[slug].vue?macro=true";
import { default as indexg6DCGN5FyGMeta } from "/opt/build/repo/pages/join-us/jobs/index.vue?macro=true";
import { default as indexD3Bk8UKru5Meta } from "/opt/build/repo/pages/link/index.vue?macro=true";
import { default as test_45pageS9Si8QUryjMeta } from "/opt/build/repo/pages/test-page.vue?macro=true";
import { default as _91slug_93P71FPN8IrvMeta } from "/opt/build/repo/pages/what-we-do/[slug].vue?macro=true";
import { default as indextfhMCsHwFuMeta } from "/opt/build/repo/pages/what-we-do/index.vue?macro=true";
import { default as _91slug_93HtUTxD0uUuMeta } from "/opt/build/repo/pages/who-we-are/[slug].vue?macro=true";
import { default as indexIMNKJUlNsnMeta } from "/opt/build/repo/pages/who-we-are/index.vue?macro=true";
export default [
  {
    name: "articleType-slug",
    path: "/:articleType()/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[articleType]/[...slug].vue")
  },
  {
    name: "page",
    path: "/:page()",
    component: () => import("/opt/build/repo/pages/[page].vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/opt/build/repo/pages/articles.vue")
  },
  {
    name: "event-test-slug",
    path: "/event-test/:slug()",
    component: () => import("/opt/build/repo/pages/event-test/[slug].vue")
  },
  {
    name: "event-test",
    path: "/event-test",
    component: () => import("/opt/build/repo/pages/event-test/index.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/opt/build/repo/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/opt/build/repo/pages/events/index.vue")
  },
  {
    name: "get-in-touch-office",
    path: "/get-in-touch/:office()",
    component: () => import("/opt/build/repo/pages/get-in-touch/[office].vue")
  },
  {
    name: "get-in-touch",
    path: "/get-in-touch",
    component: () => import("/opt/build/repo/pages/get-in-touch/index.vue")
  },
  {
    name: "get-in-touch-people-backup",
    path: "/get-in-touch/people-backup",
    component: () => import("/opt/build/repo/pages/get-in-touch/people-backup.vue")
  },
  {
    name: "get-in-touch-people-old",
    path: "/get-in-touch/people-old",
    component: () => import("/opt/build/repo/pages/get-in-touch/people-old.vue")
  },
  {
    name: "get-in-touch-people",
    path: "/get-in-touch/people",
    component: () => import("/opt/build/repo/pages/get-in-touch/people.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "job-slugs",
    path: "/job/:slugs(.*)*",
    component: () => import("/opt/build/repo/pages/job/[...slugs].vue")
  },
  {
    name: "join-us",
    path: "/join-us",
    component: () => import("/opt/build/repo/pages/join-us/index.vue")
  },
  {
    name: "join-us-jobs-slug",
    path: "/join-us/jobs/:slug()",
    component: () => import("/opt/build/repo/pages/join-us/jobs/[slug].vue")
  },
  {
    name: "join-us-jobs",
    path: "/join-us/jobs",
    component: () => import("/opt/build/repo/pages/join-us/jobs/index.vue")
  },
  {
    name: "link",
    path: "/link",
    component: () => import("/opt/build/repo/pages/link/index.vue")
  },
  {
    name: "test-page",
    path: "/test-page",
    meta: test_45pageS9Si8QUryjMeta || {},
    component: () => import("/opt/build/repo/pages/test-page.vue")
  },
  {
    name: "what-we-do-slug",
    path: "/what-we-do/:slug()",
    component: () => import("/opt/build/repo/pages/what-we-do/[slug].vue")
  },
  {
    name: "what-we-do",
    path: "/what-we-do",
    component: () => import("/opt/build/repo/pages/what-we-do/index.vue")
  },
  {
    name: "who-we-are-slug",
    path: "/who-we-are/:slug()",
    component: () => import("/opt/build/repo/pages/who-we-are/[slug].vue")
  },
  {
    name: "who-we-are",
    path: "/who-we-are",
    component: () => import("/opt/build/repo/pages/who-we-are/index.vue")
  }
]