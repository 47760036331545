<script setup>
import { ref } from "vue";
import useVisibleIntersection from "../../composables/useVisibleIntersection";
import { TransitionPresets, useTransition } from "@vueuse/core";

const props = defineProps({
  delay: {
    type: Number,
    default: 0,
  },
  duration: {
    type: Number,
    default: 1000,
  },
  scale: {
    type: Number,
    default: 100,
  },
  gradientSize: {
    type: Number,
    default: 22,
  },
});

const element = ref(null);
const isVisible = useVisibleIntersection(element);

const backgroundColor = ref("#000");
const r = ref(0);
const g = ref(0);
const b = ref(0);

const toRGB = function (str) {
  let sep = str.indexOf(",") > -1 ? "," : " ";
  return str.substr(4).split(")")[0].split(sep).map(Number);
};

const angle = ref(45);
const baseDistance = computed(() => {
  return isVisible.value ? 1 : 0;
});

let duration = props.duration;
let delay = props.delay;

const distance = useTransition(baseDistance, {
  duration: duration,
  delay: delay,
  transition: TransitionPresets.easeInQuad,
});

const getBackgroundColor = function () {
  const color = window
    .getComputedStyle(element.value, null)
    .getPropertyValue("background-color");

  if (color) {
    backgroundColor.value = color;
    const rgb = toRGB(color);
    r.value = rgb[0];
    g.value = rgb[1];
    b.value = rgb[2];
  } else {
    setTimeout(() => {
      getBackgroundColor();
    }, 10);
  }
};

onMounted(async () => {
  if (process.client && element) {
    await nextTick();
    getBackgroundColor();
  }
});
</script>

<template>
  <div
    class="transition-radial-mask"
    :style="{
      width: `${scale}%`,
      height: `${scale}%`,
      background: `radial-gradient(circle at center, rgba(${r}, ${g}, ${b}, 0) ${
        distance * (500 - gradientSize)
      }%, rgba(${r}, ${g}, ${b}, 1) ${0 + distance * 500}%)`,
    }"
  >
    <div
      class="transition-radial-mask__bg"
      ref="element"
      :style="{
        height: `${scale}%`,
        opacity: distance > 0 ? 0 : 1,
      }"
    ></div>
  </div>
</template>

<style lang="postcss" scoped>
.transition-radial-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsl(var(--theme-bg));
  }
}
</style>
