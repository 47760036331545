// import { useScroll } from "@vueuse/core";
// import { computed, ref } from "vue";

// let { y } = useScroll(null);

// try {
//   if (process) {
//     let { y } = useScroll(process.client ? window : null);
//   }
// } catch (error) {
//   // RE / process is missing for storybook, could not find the import
// }

// export default () => {
//   return {
//     currentScroll: computed(() => y.value),
//   };
// };


const { y } = useScroll(process.client ? window : null)

export default () => {
  return {
    currentScroll: computed(() => y.value),
  }
}