<script setup>
const { throwFireworks, throwConfettis, throwFromSides } = useConfettis();
import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
const pad = 20;
const threshold = "50%";
const draggable = ref([]);
onMounted(() => {
  const checkButton = document.querySelector("#check-button");
  const resetButton = document.querySelector("#reset-button");

  const dragElements = document.querySelectorAll(".drag-tile");
  const dropElements = document.querySelectorAll(".drop-tile");

  let dragTiles = Array.prototype.map.call(dragElements, createDragTile);
  let dropTiles = Array.prototype.map.call(dropElements, createDropTile);

  const getTileMiddlePoint = (tile) => {
    const { top, height, left, width } = tile.getBoundingClientRect();
    const x = (left + width / 2) / window.innerWidth;
    const y = (top + height / 2) / window.innerHeight;
    const origin = { x, y };
    return origin;
  };

  function checkAllTiles() {
    const dropTileElements = document.querySelectorAll(".drag-tile");
    const correctTiles = Array.from(dropTileElements).filter((dropTile) =>
      dropTile.classList.contains("correct")
    );
    if (correctTiles.length === 3) {
      throwFireworks();
      throwFromSides();
    }
  }

  function checkTile(tile) {
    var className;
    if (tile.value === tile.parent.value) {
      className = "correct";
      throwConfettis(getTileMiddlePoint(tile.element));
    } else {
      className = "wrong";
      gsap.fromTo(
        tile.element,
        { rotation: -5 },
        { rotation: 0, ease: "elastic.out(5, 0.2)", duration: 1 }
      );
    }
    tile.element.classList.add(className);

    // Call checkAllTiles after checking the current tile
    checkAllTiles();
  }

  function createDragTile(element, index) {
    const container = document.querySelector(".board");
    const maxX = container.offsetWidth - element.offsetWidth;
    const maxY = container.offsetHeight - element.offsetHeight;

    const randomX = Math.floor(Math.random() * maxX);
    const randomY = Math.floor(Math.random() * maxY);
    const colorsCombinations = [
      {
        background: "#9A6E44",
        color: "#F8F5E7",
      },
      {
        background: "#E4B73C",
        color: "#1F1F23",
      },
      {
        background: "#B9C7C2",
        color: "#1F1F23",
      },
      {
        background: "#30373B",
        color: "#F8F5E7",
      },
      {
        background: "#E1C1B5",
        color: "#1F1F23",
      },
      {
        background: "#451F23",
        color: "#F8F5E7",
      },
    ];
    const colors = chooseRandomValueInArray(colorsCombinations);
    gsap.set(element, {
      left: randomX,
      top: randomY,
      rotate: randomIntFromInterval(-10, 10),
      backgroundColor: colors.background,
      color: colors.color,
    });

    draggable.value[index] = new Draggable(element, {
      bounds: ".board",
      onDragStart: onDragStart,
      onDrag: onDrag,
      onDragEnd: onDragEnd,
    });

    var tile = {
      element: element,
      parent: null,
      value: element.dataset.value,
    };

    function onDragStart() {
      element.classList.remove("correct", "wrong");
    }

    function onDrag() {
      var parent = tile.parent;

      if (parent) {
        if (this.hitTest(parent.element, threshold)) {
          // exit the function
          // tile is still hitting parent, so no need to proceed any further.
          return;
        }

        // tile is no longer hitting parent, so clear any references between the two
        parent = tile.parent = parent.child = null;
      }

      for (var i = 0; i < dropTiles.length; i++) {
        var dropTile = dropTiles[i];

        if (dropTile.child) {
          // continue to next loop iteration
          // drop tile already has a child, so no need to proceed any further
          continue;
        }

        if (this.hitTest(dropTile.element, threshold)) {
          // we hit an empty drop tile, so link the two together and exit the function
          tile.parent = dropTile;
          dropTile.child = tile;
          element.classList.add("hitting");
          return;
        }
      }

      // if we made it this far, we're not hitting an empty drop tile
      element.classList.remove("hitting");
    }

    function onDragEnd() {
      var x = 0;
      var y = 0;

      // move to parent
      if (tile.parent) {
        var rect1 = element.getBoundingClientRect();
        var rect2 = tile.parent.element.getBoundingClientRect();

        x = "+=" + (rect2.left - rect1.left);
        y = "+=" + (rect2.top - rect1.top);
        gsap.to(element, {
          x: x,
          y: y,
          rotate: 0,
          duration: 0.3,
          ease: "elastic.out(1, 0.75)",
        });
        checkTile(tile);
      }
    }

    return tile;
  }

  function createDropTile(element, index) {
    gsap.set(element, {
      left: pad + 3 * element.offsetWidth,
      top: pad + index * (pad + element.offsetHeight),
    });

    var tile = {
      element: element,
      child: null,
      value: element.dataset.value,
    };

    return tile;
  }
  function handleResize() {
    // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
    if (
      window.innerWidth != windowWidth
      // ||
      // window.innerHeight != windowHeight
    ) {
      // Update the window width for next time
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
      // console.log("handleResize", draggable.value);
      draggable.value.forEach((draggable) => {
        draggable?.kill();
      });
      dragTiles = null;
      dropTiles = null;
      dragTiles = Array.prototype.map.call(dragElements, createDragTile);
      dropTiles = Array.prototype.map.call(dropElements, createDropTile);

      // Do stuff here
    }

    // Otherwise do nothing
  }
  var windowWidth = window.innerWidth;
  var windowHeight = window.innerHeight;
  window.addEventListener("resize", handleResize);
  handleResize();
});

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function chooseRandomValueInArray(array) {
  return array[Math.floor(Math.random() * array.length)];
}
const minTiles = 2;
const maxTiles = isMobile ? 3 : 5;
</script>

<template>
  <div class="main">
    <section class="board">
      <div class="layer drop-layer">
        <div class="tile drop-tile" data-value="4"></div>
        <div class="tile drop-tile" data-value="0"></div>
        <div class="tile drop-tile" data-value="4"></div>
      </div>

      <div class="layer drop-layer drag-layer">
        <div
          v-for="(tile, index) in randomIntFromInterval(minTiles, maxTiles)"
          class="tile drag-tile ts-mega"
          data-value="4"
        >
          4
        </div>
        <div
          v-for="(tile, index) in randomIntFromInterval(minTiles, maxTiles)"
          class="tile drag-tile ts-mega"
          data-value="0"
        >
          0
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
.main {
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% - 53px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
}

.board {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tile {
  width: 3rem;
  height: 5rem;
  @media (--md) {
    width: 6rem;
    height: 10rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;

  background: dodgerblue;
  color: rgba(255, 255, 255, 0.9);

  border-radius: 0 4px 20px 4px;
  border: 6px solid transparent;
}

.drag-tile {
  position: absolute;
  background: #1976d2;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);

  font-family: var(--grotzec);
  font-size: units(8);
}

.drop-tile {
  border: 1px solid var(--Stroke-dark, rgba(31, 32, 35, 0.3));
  background: var(--Off-white, #f8f5e7);

  box-shadow: 0px 8.571px 20px -4px rgba(0, 0, 0, 0.05);
  margin: 1rem;
}

.layer {
  width: 100%;
  height: 100%;
}

.drop-layer {
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.drag-layer {
  pointer-events: all;
  z-index: 1;
}

.hitting {
  border-color: rgba(255, 255, 255, 0.35);
}

.correct {
  background: #4caf50;
}

.wrong {
  background: #c62828;
}
</style>
