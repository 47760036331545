// store/index.js
const state = () => ({
    disableHash: false,
});
const mutations = {
    SET_DISABLE_HASH(state, value) {
        state.disableHash = value;
    },
};

const actions = {
    setDisableHash({ commit }, value) {
        commit("SET_DISABLE_HASH", value);
    },
};

const getters = {
    disableHash: (state) => state.disableHash,
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}