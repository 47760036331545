<script setup>
import { ref } from "vue";
import { TransitionPresets, useTransition } from "@vueuse/core";
import useVisibleIntersection from "../../composables/useVisibleIntersection";
import { isSafari } from "~/utils";

const props = defineProps({
  delay: {
    type: Number,
    default: 0, // in seconds
  },
  duration: {
    type: Number,
    default: 1000, // in milliseconds
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  customColor: {
    type: String,
    default: null,
  },
});

const element = ref(null);
const isVisible = useVisibleIntersection(element);

const backgroundColor = ref(null);
const r = ref(0);
const g = ref(0);
const b = ref(0);

const toRGB = function (str) {
  let sep = str.indexOf(",") > -1 ? "," : " ";
  return str.substr(4).split(")")[0].split(sep).map(Number);
};

const angle = ref(45);

const baseDistance = computed(() => {
  return isVisible.value ? 1 : 0;
});

let duration = isSafari() ? props.duration / 2 : props.duration;
let delay = props.delay;

const distance = useTransition(baseDistance, {
  duration: duration,
  delay: delay * duration,
  transition: TransitionPresets.easeInQuad,
});

const getBackgroundColor = function () {
  let color = window
    .getComputedStyle(element.value, null)
    .getPropertyValue("background-color");

  if (props.customColor != null) color = props.customColor;

  if (color) {
    backgroundColor.value = color;
    const rgb = toRGB(color);
    r.value = rgb[0];
    g.value = rgb[1];
    b.value = rgb[2];
  } else {
    setTimeout(() => {
      getBackgroundColor();
    }, 100);
  }
};

onMounted(async () => {
  if (process.client && element) {
    await nextTick();
    getBackgroundColor();
  }
});
</script>

<template>
  <div
    class="transition-linear-mask"
    :style="{
      height: height > 0 ? `${height}px` : '100%',
      width: width > 0 ? `${width}px` : '100%',
      background: backgroundColor
        ? `linear-gradient( 90deg, rgba(${r}, ${g}, ${b}, 0) ${
            distance * 100
          }%, rgba(${r}, ${g}, ${b}, 1) ${50 + distance * 150}%)`
        : 'transparent',
    }"
  >
    <div
      class="transition-linear-mask__bg"
      ref="element"
      :style="{
        height: height > 0 ? `${height}px` : '100%',
        width: width > 0 ? `${width}px` : '100%',
        opacity: distance > 0 ? 0 : 1,
      }"
    ></div>
  </div>
</template>

<style lang="postcss" scoped>
.transition-linear-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &__bg {
    background: hsl(var(--theme-bg));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
  }
}
</style>
