const history = ref(null)

if (process.client) {
    window.nuxtHistory = () => history.value
}

/**
 * Custom hook for managing navigation history.
 * @param {boolean} isController - Indicates if the component is a controller.
 * @returns {Object} - Object containing history, get, and getLast functions.
 */
export function useNavigationHistory(isController) {
    const { currentScroll } = useCurrentScroll()
    const route = useRoute()

    if (!history.value) {
        history.value = [{ path: route.path }]
    }

    if (isController) {
        const set = () => {
            history.value[0].scrollPos = currentScroll.value
            history.value.unshift({ path: route.path })
        }

        watch(() => route.path, set)
    }

    const get = () => [...history.value]

    const getLast = () => {
        const lastIndex = Math.min(history.value.length - 1, 2)
        return {
            scrollPos: history.value[lastIndex].scrollPos,
            navigatingBack: history.value[lastIndex].path === route.path,
        }
    }

    return {
        history,
        get,
        getLast,
    }
}