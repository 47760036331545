const state = {
  modals: [],
};

const mutations = {
  MODAL_OPEN(state, { id, data }) {
    state.modals.unshift({ id, data });
  },
  MODAL_CLOSE(state, { id }) {
    state.modals = state.modals.filter((e) => e.id !== id);
  },
};

const actions = {
  open({ commit }, { id, data }) {
    commit("MODAL_OPEN", { id, data });
  },
  close({ commit }, { id }) {
    commit("MODAL_CLOSE", { id });
  },
};

const getters = {
  active: (state) => (state.modals.length > 0 ? state.modals[0] : null),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
