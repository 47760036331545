const state = {
  height: 0,
  logoHeight: 0,
  logoWidth: 0,
};

const mutations = {
  SET_HEIGHT(state, size) {
    state.height = size;
  },
  SET_LOGO_HEIGHT(state, size) {
    state.logoHeight = size;
  },
  SET_LOGO_WIDTH(state, size) {
    state.logoWidth = size;
  },
};

const actions = {
  setHeight({ commit }, size) {
    commit("SET_HEIGHT", size);
  },
  setLogoHeight({ commit }, size) {
    commit("SET_LOGO_HEIGHT", size);
  },
  setLogoWidth({ commit }, size) {
    commit("SET_LOGO_WIDTH", size);
  },
};

const getters = {
  height: (state) => state.height,
  logoHeight: (state) => state.logoHeight,
  logoWidth: (state) => state.logoWidth,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
