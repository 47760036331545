import { createStore } from "vuex";
import modals from "./modules/modals";
import theme from "./modules/theme";
import header from "./modules/header";
import hash from "./modules/hash";

export default createStore({
  modules: {
    modals,
    theme,
    header,
    hash,
  },
});
