<script setup>
import { useElementSize } from "@vueuse/core";
import { useStore } from "vuex";

const logo = ref(null);
const { height: logoHeight, width: logoWidth } = useElementBounding(logo);
const store = useStore();

const onResize = function () {
  store.dispatch("header/setLogoHeight", logoHeight.value);
  store.dispatch("header/setLogoWidth", logoWidth.value);
};

onMounted(() => {
  if (process.client) {
    window.addEventListener("resize", onResize);
  }
  setTimeout(() => {
    onResize();
  }, 500);
  onResize();
});

onUnmounted(() => {
  if (process.client) {
    window.removeEventListener("resize", onResize);
  }
});
</script>

<template>
  <svg
    ref="logo"
    width="100%"
    height="100%"
    viewBox="0 0 47 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.69535 20V0H0V20H3.69535Z" fill="currentColor" />
    <path
      d="M28.4591 20V0H23.8047L18.5296 14.2454L13.2828 0H8.37447V20H11.9006V5.55712L17.0628 19.7179H19.7426L24.933 5.72638V20H28.4591Z"
      fill="currentColor"
    />
    <path
      d="M46.935 19.9806V17.6299H31.7305V19.9806H46.935Z"
      fill="currentColor"
    />
  </svg>
</template>
