import { defineNuxtPlugin } from "#app";
import store from "@/stores";

export default defineNuxtPlugin((nuxtApp) => {

  nuxtApp.vueApp.use(store);

  // nuxtApp.$router.options.scrollBehavior = (to, from, savedPosition) => {
  //   // RE / If only the hash is changing do not scroll to top, but handle this at chapter-list.vue

  //   if (from.path == to.path || to.hash.length > 0) {
  //     return null;
  //   }
  // };
});
