const state = {
  theme: null,
};

const mutations = {
  SELECT_THEME(state, { theme }) {
    state.theme = theme;
  },
  REMOVE_THEME(state) {
    state.theme = null;
  },
};

const actions = {
  selectedTheme({ commit }, { theme }) {
    commit("SELECT_THEME", { theme });
  },
  removeTheme({ commit }) {
    commit("REMOVE_THEME");
  },
};

const getters = {
  selected: (state) => state.theme,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
