import useCurrentScroll from "./useCurrentScroll";
import { useWindowSize, useRafFn } from "@vueuse/core";
import { computed, ref, watch } from "vue";

// dependencies
const { currentScroll } = useCurrentScroll();
const { width } = useWindowSize();

// state
const TRIGGER_POINT = 30; // made up number ~half of header height
const themesOnThePage = ref([]);
const currentTheme = ref(null);

// helpers
const currentBackground = computed(
  () => currentTheme.value?.background.map((x) => parseInt(x)) || [0, 0, 100]
);
// ['0', '0%', '100%'] -> '0 0% 100%'
const toHSL = (color) => (!color ? null : color.join(" "));
const toCSS = (theme, isGlobal) => {
  if (!theme) return {};

  if (isGlobal) {
    return {
      "--global-theme-text-color": theme.text,
      "--global-theme-background-color": toHSL(theme.background),
    };
  }

  return {

    "--theme-text": theme.text,
    "--theme-bg": toHSL(theme.background),
  };
};

const replaceTheme = (idx, newtheme) => {
  themesOnThePage.value[idx] = newtheme;
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function lerpColor(a, b, amount) {
  var ah = parseInt(a.replace(/#/g, ""), 16),
    ar = ah >> 16,
    ag = (ah >> 8) & 0xff,
    ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ""), 16),
    br = bh >> 16,
    bg = (bh >> 8) & 0xff,
    bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return (
    "#" + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
  );
}

/**
 * update mobile theme color
 */
const lastColor = ref(null);
const progress = ref(0);

const { resume, pause } = useRafFn(() => {
  progress.value += 0.075;

  const themeBackgroundColorAsHex = hslToHex(...currentBackground.value);

  useHead({
    meta: [
      {
        name: "theme-color",
        content: lerpColor(
          lastColor.value || "#ffffff",
          themeBackgroundColorAsHex,
          Math.min(1, progress.value)
        ),
      },
    ],
  });

  if (progress.value > 1) {
    pause();
    lastColor.value = themeBackgroundColorAsHex;
    progress.value = 0;
  }
});

watch(
  currentTheme,
  () => {
    if (!currentTheme.value) return;
    if (lastColor.value !== hslToHex(...currentBackground.value)) {
      resume();
    }
  },
  { deep: true, immediate: true }
);

/**
 * scroll handler/updater
 */
watch(
  [currentScroll, width],
  () => {
    const newCurrentTheme = themesOnThePage.value.find((theme) => {
      if (!theme.element) return;

      const { top, bottom } = theme.element.getBoundingClientRect();

      return top <= TRIGGER_POINT && bottom > TRIGGER_POINT;
    });

    currentTheme.value = newCurrentTheme?.theme || null;
  },

  { deep: true, immediate: true }
);
/**
 * new page handler/updater after a small timeout so we get the correct values
 */
watch(
  [themesOnThePage],
  () => {
    setTimeout(() => {
      const newCurrentTheme = themesOnThePage.value.find((theme) => {
        if (!theme.element) return;
        const { top, bottom } = theme.element.getBoundingClientRect();
        return top <= 0 && bottom > 0;
      });
      currentTheme.value = newCurrentTheme?.theme || null;
    }, 500);
  },

  { deep: true, immediate: true }
);

export default function (newTheme) {
  if (newTheme) {
    watch(newTheme.element, () => {
      themesOnThePage.value.push(newTheme);
    });
  }

  return {
    theme: currentTheme,
    currentThemeCSSVariables: computed(() => {
      //console.log("RE / [useTheme.js:131]: currentTheme: ", currentTheme.value);
      if (!currentTheme.value) return {};
      return {
        "--global-theme-text-color": currentTheme.value.text,
        "--global-theme-background-color": toHSL(currentTheme.value.background),
      };
    }),
    toHSL,
    toCSS,
    replaceTheme,
    clearTheme: () => {
      themesOnThePage.value = [];
    },
  };
}
