import confetti from "canvas-confetti";

export default () => {
    let isThrowingFireworks = false;
    let isThrowingFromSides = false;
    let isThrowingConfettis = false;

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }


    const throwFireworks = () => {
        if (isThrowingFireworks) {
            return; // Return if already running
        }

        isThrowingFireworks = true;

        var duration = 5 * 1000;
        var animationEnd = Date.now() + duration;
        var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        var interval = setInterval(function () {
            var timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                clearInterval(interval);
                isThrowingFireworks = false;
                return;
            }

            var particleCount = 50 * (timeLeft / duration);
            // since particles fall down, start a bit higher than random
            confetti({
                ...defaults,
                particleCount,
                origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
            });
            confetti({
                ...defaults,
                particleCount,
                origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
            });
        }, 250);
    }

    const throwFromSides = () => {
        if (isThrowingFromSides) {
            return; // Return if already running
        }

        isThrowingFromSides = true;

        var end = Date.now() + (10 * 1000);

        var colors = ['#F71873', '#6DD0E7', '#B4DF86'];

        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            } else {
                isThrowingFromSides = false;
            }
        }());
    }

    const throwConfettis = (originVal = { x: 0, y: 0 }) => {
        if (isThrowingConfettis) {
            return; // Return if already running
        }

        isThrowingConfettis = true;

        var count = 100;
        var defaults = {
            origin: originVal
        };

        function fire(particleRatio, opts) {
            confetti({
                ...defaults,
                ...opts,
                particleCount: Math.floor(count * particleRatio)
            });
        }

        fire(0.25, {
            spread: 200,
            startVelocity: 25,
        });
        fire(0.2, {
            spread: 60,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 12,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
        });

        isThrowingConfettis = false;
    }

    return {
        throwFireworks,
        throwFromSides,
        throwConfettis
    }
}
